/* eslint-disable react/no-danger */
import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../../zzz/layout"
import Grid from "../../zzz/layout/grid"
import PageInner from "../../zzz/layout/pageInner"

const ContactSuccessPage = ({ location }) => {
  const data = { wp: { title: "title", content: "content" } }
  // useStaticQuery(graphql`
  //   {
  //     wp: wordpressPage(wordpress_id: { eq: 88 }) {
  //       title
  //       content
  //     }
  //   }
  // `)

  return (
    <Layout location={location}>
      <PageInner>
        <Grid>
          <h1>{data.wp.title}</h1>
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: data.wp.content }}
          />
        </Grid>
      </PageInner>
    </Layout>
  )
}

export default ContactSuccessPage
